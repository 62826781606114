import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as RouteLink, useLocation, useNavigate } from "react-router-dom";
import logo from "./../../images/delivery-logo.png";

import * as Scroll from "react-scroll";
import "./style.css";
import { GiCarWheel } from "react-icons/gi";
function Navbar1() {
  const [navToggle, setnavToggle] = useState(true);
  const [navtitle, setNavTitle] = useState(null);
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    await scroller.scrollTo(selector, {
      duration: 800,
      smooth: false,
    });
  };

  return (
    <>
      <nav className="navbar navbar-default  navbar-expand-md">
        <div className="navbar-header">
          <Link
            to="home"
            className="header-logo tw-bg-[#FF0000] tw-bg-opacity-30 tw-rounded-lg"
            smooth={false}
            duration={800}
          >
            <RouteLink to={"/"}>
              {/* <span></span> */}
              <img src={logo} alt="logo" className="tw-w-60" />
            </RouteLink>
          </Link>
          <div className="tw-flex tw-items-center tw-justify-end">
            <button
              onClick={() => setnavToggle(!navToggle)}
              type="button"
              className={`navbar-toggle tw-transform ${
                navToggle
                  ? "tw-rotate-180 tw-transition-transform tw-duration-700"
                  : "tw-transition-transform tw-duration-700"
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#bs-example-navbar-collapse-1"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <GiCarWheel size={55} color="white" />
            </button>
          </div>
        </div>
        <div
          className={
            navToggle
              ? "collapse navbar-collapse"
              : "collapse navbar-collapse show"
          }
          id="bs-example-navbar-collapse-1"
        >
          <ul
            className="nav navbar-nav navbar-right menu-item-6"
            id="navbar-full"
          >
            {location.length < 1 ? (
              <>
                <li
                  className={`service  ${
                    navtitle !== null && navtitle !== "service"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("service")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow ">
                    Hızlı Yemek Siparişi
                  </span>
                  <RouteLink
                    to={"/hizliyemek#!"}
                    className="link-item service "
                    onClick={() => goToPageAndScroll("portfolios")}
                  >
                    HızlıYemekSiparişi
                  </RouteLink>
                </li>

                <li
                  className={`home ${
                    navtitle !== null && navtitle !== "home"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                >
                  <span className="rotated-title effect textshadow">
                    Kurye Başvuru
                  </span>
                  <RouteLink
                    to={"/kuryebasvuru#!"}
                    className="link-item home "
                    onClick={() => goToPageAndScroll("kuryeformu")}
                  >
                    KuryeBaşvuru
                  </RouteLink>
                </li>
                <li
                  className={`about ${
                    navtitle !== null && navtitle !== "about"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("about")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Restoran Başvuru
                  </span>
                  <Link
                    to="portfolio"
                    className="link-item about"
                    smooth={false}
                    duration={800}
                    spy={true}
                    activeClass="active"
                  >
                    RestoranBaşvuru
                  </Link>
                </li>

                <li
                  className={`work ${
                    navtitle !== null && navtitle !== "work"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("work")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Referanslarımız
                  </span>
                  <Link
                    to="referans"
                    className="link-item work "
                    smooth={false}
                    duration={800}
                    spy={true}
                    activeClass="active"
                  >
                    Referanslarımız
                  </Link>
                </li>
                <li
                  className={`danisma ${
                    navtitle !== null && navtitle !== "danisma"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("danisma")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Danışma
                  </span>
                  <RouteLink
                    to={"/danisma#!"}
                    className="link-item danisma"
                    onClick={() => goToPageAndScroll("danisma")}
                  >
                    Danışma
                  </RouteLink>
                </li>
                <li
                  className={`contact ${
                    navtitle !== null && navtitle !== "contact"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("contact")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    İletişim
                  </span>
                  <Link
                    to="contacts"
                    className="link-item contact"
                    smooth={false}
                    duration={800}
                    spy={true}
                    activeClass="active"
                  >
                    İletişim
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li
                  className={`service ${
                    navtitle !== null && navtitle !== "service"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("service")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Hızlı Yemek Siparişi
                  </span>
                  <RouteLink to="/hizliyemek" className="link-item service">
                    HızlıYemekSiparişi
                  </RouteLink>
                </li>
                <li
                  className={`home ${
                    navtitle !== null && navtitle !== "home"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("home")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Kurye Başvuru
                  </span>
                  <RouteLink to="/kuryebasvuru" className="link-item home">
                    KuryeBaşvuru
                  </RouteLink>
                </li>
                <li
                  className={`about ${
                    navtitle !== null && navtitle !== "about"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("about")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Restoran Başvuru
                  </span>
                  <a
                    href="/#!"
                    className="link-item about"
                    onClick={() => goToPageAndScroll("portfolio")}
                  >
                    RestoranBaşvuru
                  </a>
                </li>

                <li
                  className={`work ${
                    navtitle !== null && navtitle !== "work"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("work")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Referanslarımız
                  </span>
                  <a
                    href="/#!"
                    className="link-item work"
                    onClick={() => goToPageAndScroll("referans")}
                  >
                    Referanslarımız
                  </a>
                </li>
                <li
                  className={`danisma ${
                    navtitle !== null && navtitle !== "danisma"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("danisma")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    Danışma
                  </span>
                  <RouteLink to="/danisma" className="link-item danisma">
                    Danışma
                  </RouteLink>
                </li>
                <li
                  className={`contact ${
                    navtitle !== null && navtitle !== "contact"
                      ? "tw-opacity-60"
                      : ""
                  } `}
                  onMouseEnter={() => setNavTitle("contact")}
                  onMouseLeave={() => setNavTitle(null)}
                >
                  <span className="rotated-title effect textshadow">
                    İletişim
                  </span>
                  <a
                    href="/#!"
                    className="link-item contact"
                    onClick={() => goToPageAndScroll("contacts")}
                  >
                    İletişim
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar1;
