import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import Kuryemail from "../Emails/Kuryemail";
import { render } from "@react-email/render";
import axios from "axios";
import { API_URL } from "../../lib/config";
import Accordion from "react-bootstrap/Accordion";

Modal.setAppElement("#root");
function Kuryemform() {
  const {
    register,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm();
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/set")
        .then((res) => {
          const basvuru = res.data.kuryebasvuru;
          setData(basvuru);
        })
        .catch((error) => {
          console.log(error);
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, [reset]);
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [tel, setTel] = useState("");
  const [telerror, setTelError] = useState("");
  const [check, setCheck] = useState(false);
  const [senerror, setSenError] = useState(false);
  const [senaryo, setSenaryo] = useState(null);
  const onSubmit = async (data) => {
    if (tel?.length < 7) {
      setTelError("Telefon Giriniz");
      return;
    }
    if (senaryo === null) {
      setSenError(true);
      return;
    }
    let settings = null;
    await axios
      .get(API_URL + "/set")
      .then((res) => {
        settings = res.data;
      })
      .catch((error) => {
        alert("Hata Oluştu");
      });

    const formData = { ...data, senaryo: senaryo, tel: tel };

    const html = render(
      <Kuryemail formData={formData} basvuruData={settings.kuryebasvuru} />
    );

    const myformData = { html: html, role: "Delivery Wi Kurye Başvuru" };

    await axios
      .post(API_URL + "/sendmail", myformData)
      .then((res) => {
        setModalIsOpen(true);
      })
      .catch((error) => alert("Hata Oluştu"));
  };

  const handleDeneyimtecChange = (event) => {
    const value = event.target.value;

    if (value && value !== "") {
      setCheck(true);
      setValue("deneyimtec", value);
    } else {
      setCheck(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="popup-send"
      >
        <div className="block-popup">
          <h4> Teşekkürler,</h4>
          <h4>Başvurunuz Başarıyla Alınmıştır </h4>
        </div>
        <span
          onClick={() => setModalIsOpen(false)}
          className="close-btn"
        ></span>
      </Modal>
      <div id="kuryeformu" className="container tw-mt-6">
        <div id="form-wrapper">
          <div id="form-inner">
            <div id="MainResult"></div>
            <div id="MainContent">
              <form
                id="MyContactForm"
                name="KuryeForm"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      <label htmlFor="name" id="nameLb">
                        <span className="!tw-capitalize tw-text-white tw-text-sm">
                          Ad :
                        </span>
                        <span className="error">{errors?.ad?.message}</span>
                      </label>
                      <input
                        type="text"
                        name="ad"
                        id="ad"
                        placeholder="Adınız*"
                        maxLength={35}
                        {...register("ad", {
                          required: "Adınızı giriniz.",
                        })}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      <label htmlFor="soyad" id="soyadLb">
                        <span className="!tw-capitalize tw-text-white tw-text-sm">
                          Soyad :
                        </span>
                        <span className="error">{errors?.soyad?.message}</span>
                      </label>
                      <input
                        type="text"
                        name="soyad"
                        id="soyad"
                        placeholder="Soyadınız*"
                        maxLength={35}
                        {...register("soyad", {
                          required: "Soyadı giriniz.",
                        })}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      <label htmlFor="telefon" id="telefonLb">
                        <span className="!tw-capitalize tw-text-white tw-text-sm">
                          Telefon :
                        </span>
                        <span className="error">{telerror}</span>
                      </label>
                      <input
                        type="text"
                        name="tel"
                        id="tel"
                        placeholder="Telefon*"
                        value={tel}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value) && value.length <= 15) {
                            setTel(value);
                          }
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      <label htmlFor="dtarih" id="dtarihLb">
                        <span className="!tw-capitalize tw-text-white tw-text-sm">
                          Doğum Tarihi :
                        </span>
                        <span className="error">{errors?.dtarih?.message}</span>
                      </label>
                      <input
                        type="date"
                        name="dtarih"
                        id="dtarih"
                        className="tw-text-slate-400 "
                        {...register("dtarih", {
                          required: "Doğum Tarihini giriniz.",
                        })}
                      />
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      <label htmlFor="email" id="emailLb">
                        <span className="!tw-capitalize tw-text-white tw-text-sm">
                          Email :
                        </span>
                        <span className="error">{errors?.email?.message}</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        {...register("email", {
                          required: "Email giriniz.",
                        })}
                      />
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <p className="textarea">
                      <span className="!tw-capitalize tw-text-white tw-text-sm">
                        Adress :
                      </span>
                      <textarea
                        id="adress"
                        placeholder="Adress Giriniz*"
                        rows="3"
                        maxLength={150}
                        {...register("adress")}
                      ></textarea>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 tw-mt-0 lg:tw-mt-6 tw-mb-2 tw-flex tw-flex-col tw-gap-2">
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <p className="tw-font-extrabold ">Ehliyet Cinsi :</p>
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <p className="tw-text-white tw-text-sm">A1 </p>
                        <input
                          type="checkbox"
                          className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                          id="ehliyetcheck"
                          {...register("A1check")}
                        />

                        <p className="tw-text-white tw-text-sm">A2 </p>
                        <input
                          type="checkbox"
                          className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                          id="ehliyetcheck"
                          {...register("A2check")}
                        />
                        <p className="tw-text-white tw-text-sm">A </p>
                        <input
                          type="checkbox"
                          className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                          id="ehliyetcheck"
                          {...register("Acheck")}
                        />
                        <p className="tw-text-white tw-text-sm">B </p>
                        <input
                          type="checkbox"
                          className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                          id="ehliyetcheck"
                          {...register("Bcheck")}
                        />
                      </div>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <p className="tw-font-extrabold ">Araç Cinsi :</p>
                      <label className="tw-flex tw-items-center tw-gap-2">
                        <p className="tw-text-white tw-text-sm tw-text-nowrap">
                          Motor :
                        </p>
                        <input
                          type="checkbox"
                          className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                          {...register("Motor")}
                        />
                        <p className="tw-text-white tw-text-sm tw-text-nowrap">
                          Araba :
                        </p>
                        <input
                          type="checkbox"
                          className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                          name="ehliyetcheck"
                          id="ehliyetcheck"
                          {...register("Araba")}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 tw-mt-0 lg:tw-mt-5 tw-mb-2 tw-flex tw-flex-col tw-gap-2">
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <p className="tw-font-extrabold tw-text-wrap md:tw-text-nowrap">
                        Deneyim Tecrübe (Varsa) :
                      </p>
                      <input
                        type="number"
                        id="deneyimtec"
                        min={0}
                        className="tw-mb-0 tw-max-w-24"
                        {...register("deneyimtec")}
                        onChange={handleDeneyimtecChange}
                      />
                      <p className="tw-text-white tw-text-sm">Yıl</p>
                      <br />
                      <p className="tw-font-extrabold tw-text-sm tw-text-wrap md:tw-text-nowrap">
                        Yeni Başlıyorum :
                      </p>
                      <input
                        type="checkbox"
                        className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                        disabled={check}
                        {...register("Yenicheck")}
                      />
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <p className="tw-font-extrabold tw-text-sm tw-block lg:tw-hidden">
                        Daha önce nere- lerde çalıştınız :
                      </p>
                      <p className="tw-font-extrabold tw-text-base tw-hidden lg:tw-block">
                        Daha önce nerelerde çalıştınız :
                      </p>
                      <input
                        type="text"
                        id="deneyimyer"
                        className="tw-mb-0  lg:tw-w-80"
                        placeholder="Deneyim Yerleri"
                        {...register("deneyimyer")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tw-my-3 md:tw-my-6">
                    <div className="tw-w-full tw-text-center tw-px-4 tw-py-2 tw-rounded-full tw-border-2 tw-border-white tw-text-[#999999] tw-text-xl tw-uppercase">
                      Sana uygun başvurulardan birini seç
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Accordion defaultActiveKey="1">
                      {data?.basvuruOne?.check && (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header
                            onClick={() => {
                              setSenaryo("basvuru1");
                              setSenError(false);
                            }}
                          >
                            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-start">
                              <input
                                type="checkbox"
                                className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                                id="senaryo1"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSenaryo("basvuru1");
                                    setSenError(false);
                                  }
                                }}
                                checked={senaryo === "basvuru1"}
                              />
                              <span className="tw-text-nowrap">
                                {data?.basvuruOne?.name}{" "}
                                <span className="error tw-pl-2">
                                  {senerror &&
                                    "Lütfen Başvurulardan Birini Seç"}
                                </span>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="!tw-bg-[#2D2D2D] tw-text-[#999999]">
                            {data?.basvuruOne?.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {data?.basvuruTwo?.check && (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header
                            onClick={() => {
                              setSenaryo("basvuru2");
                              setSenError(false);
                            }}
                          >
                            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-start">
                              <input
                                type="checkbox"
                                className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                                id="senaryo2"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSenaryo("basvuru2");
                                    setSenError(false);
                                  }
                                }}
                                checked={senaryo === "basvuru2"}
                              />
                              <span className="tw-text-nowrap">
                                {data?.basvuruTwo?.name}{" "}
                                <span className="error tw-pl-2">
                                  {senerror &&
                                    "Lütfen Başvurulardan Birini Seç"}
                                </span>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="!tw-bg-[#2D2D2D] tw-text-[#999999]">
                            {data?.basvuruTwo?.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {data?.basvuruThree?.check && (
                        <Accordion.Item eventKey="3">
                          <Accordion.Header
                            onClick={() => {
                              setSenaryo("basvuru3");
                              setSenError(false);
                            }}
                          >
                            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-start">
                              <input
                                type="checkbox"
                                className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                                id="senaryo3"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSenaryo("basvuru3");
                                    setSenError(false);
                                  }
                                }}
                                checked={senaryo === "basvuru3"}
                              />
                              <span className="tw-text-nowrap">
                                {data?.basvuruThree?.name}{" "}
                                <span className="error tw-pl-2">
                                  {senerror &&
                                    "Lütfen Başvurulardan Birini Seç"}
                                </span>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="!tw-bg-[#2D2D2D] tw-text-[#999999]">
                            {data?.basvuruThree?.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {data?.basvuruFour?.check && (
                        <Accordion.Item eventKey="4">
                          <Accordion.Header
                            onClick={() => {
                              setSenaryo("basvuru4");
                              setSenError(false);
                            }}
                          >
                            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-start">
                              <input
                                type="checkbox"
                                className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                                id="senaryo4"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSenaryo("basvuru4");
                                    setSenError(false);
                                  }
                                }}
                                checked={senaryo === "basvuru4"}
                              />
                              <span className="tw-text-nowrap">
                                {data?.basvuruFour?.name}{" "}
                                <span className="error tw-pl-2">
                                  {senerror &&
                                    "Lütfen Başvurulardan Birini Seç"}
                                </span>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="!tw-bg-[#2D2D2D] tw-text-[#999999]">
                            {data?.basvuruFour?.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {data?.basvuruFive?.check && (
                        <Accordion.Item eventKey="5">
                          <Accordion.Header
                            onClick={() => {
                              setSenaryo("basvuru5");
                              setSenError(false);
                            }}
                          >
                            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-start">
                              <input
                                type="checkbox"
                                className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                                id="senaryo"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSenaryo("basvuru5");
                                    setSenError(false);
                                  }
                                }}
                                checked={senaryo === "basvuru5"}
                              />
                              <span className="tw-text-nowrap">
                                {data?.basvuruFive?.name}{" "}
                                <span className="error tw-pl-2">
                                  {senerror &&
                                    "Lütfen Başvurulardan Birini Seç"}
                                </span>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="!tw-bg-[#2D2D2D] tw-text-[#999999]">
                            {data?.basvuruFive?.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {data?.basvuruSix?.check && (
                        <Accordion.Item eventKey="6">
                          <Accordion.Header
                            onClick={() => {
                              setSenaryo("basvuru6");
                              setSenError(false);
                            }}
                          >
                            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-start">
                              <input
                                type="checkbox"
                                className="tw-mb-0 tw-min-w-4 tw-min-h-4"
                                id="senaryo"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSenaryo("basvuru6");
                                    setSenError(false);
                                  }
                                }}
                                checked={senaryo === "basvuru6"}
                              />
                              <span className="tw-text-nowrap">
                                {data?.basvuruSix?.name}{" "}
                                <span className="error tw-pl-2">
                                  {senerror &&
                                    "Lütfen Başvurulardan Birini Seç"}
                                </span>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="!tw-bg-[#2D2D2D] tw-text-[#999999]">
                            {data?.basvuruSix?.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    </Accordion>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="text-center tw-mt-6">
                  <input
                    type="submit"
                    className="contact-btn btn-move t2"
                    value="Gönder"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Kuryemform;
